import React from 'react';
import { Layout } from '../components/common';

const NotFoundPage = ({location}) => (
  <Layout
    seo={{
      title: 'Gatsby Starter',
      lang: 'en',
      description: 'This is a barebones starter for Gatsby'
    }}
    location={location}
  >
    <h1>Page not found</h1>
  </Layout>
);

export default NotFoundPage;
